<template>
  <div class="mobile-list">
    <searchBox placeholder="请输入关键字" :onTextChanged="textChanged" :onCancel="cancel" :onSearchClicked="searchClicked" :msg="enterkey"></searchBox>

    <div class="mobile-list-content">
      <orderScroll ref="scroll" :data="items" :options="options" :loading="loading" :firstPage="firstPage" :nextPage="nextPage">
        <itemBox v-for="(item, index) in items" :code="getTypeName(item.model.type)" :stateName="item.model.user_name" stateColor="grey-font-color" :name="item.model.pname" :total="item.model.total" :memo="formatDate(item.model.time,'yyyy-MM-dd hh:mm')" :key="index"
          @click.native="itemClick(item)" />
      </orderScroll>
    </div>
  </div>
</template>

<script>
import { GetRemindList } from "@/api";
import { formatDate } from "@/utils/format.js";
import { loadListAction } from "@/utils/action.js";

export default {
  data: function () {
    return {
      items: [],
      options: {
        pullDownRefresh: {
          threshold: 60,
          stopTime: 500,
          txt: "刷新成功",
        },
        pullUpLoad: true,
      },
      pageIndex: 1,
      pageSize: 10,
      total: 0,
      loading: false,
      hasMore: true,
      filterOption: {
        startTime: null,
        endTime: null,
        orderStatusKey: 0,
        orderTypeKey: null,
      },
      enterkey: [],
      keyword: "",
    };
  },
  methods: {
    formatDate: formatDate,
    getTypeName: function (type) {
      switch (type) {
        case 1:
          return "即将到期";
        default:
          return "已经逾期";
      }
    },
    cancel: function () {
      this.enterkey.length = 0;
    },
    itemClick: function (item) {
      this.$router.push({
        name: "mRemindDetail",
        query: {
          id: item.model.id,
        },
      });
    },
    textChanged: function (keywords) {
      this.keyword = keywords;
    },
    searchClicked: function (keywords) {
      this.keyword = keywords;
      this.firstPage();
    },
    firstPage: function () {
      this.pageIndex = 1;
      this.items = [];
      this.dataRequest();
    },
    nextPage: function () {
      console.log("a");
      if (this.loading || !this.hasMore) {
        this.$refs.scroll.forceUpdate(true);
      } else {
        this.dataRequest();
      }
    },
    dataRequest: function () {
      loadListAction(this, GetRemindList, {
        keyword: this.keyword,
        page: {
          pageIndex: this.pageIndex,
          pageSize: this.pageSize,
          orderBy: "",
        }
      }, (data) => {
        this.total = data.totalCount;
        if (this.pageIndex == 1) {
          this.items = [];
        }
        if (data.list.length > 0) {
          this.items = this.items.concat(data.list);
          this.pageIndex += 1;
        }
        this.hasMore = this.total > this.items.length;
        this.$refs.scroll.forceUpdate(true);
      });
    },
  },
  mounted: function () {
    this.firstPage();
  }
};
</script>
